import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from 'reactstrap';
import ModelProduct from '../../../../Models/Product';
import {showNotification} from "../../../../shared/components/Notification";
import AddProductToList from "../../../../shared/components/AddProductToList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faCheck, faQuestionCircle, faPlus} from '@fortawesome/fontawesome-free-solid';
import Carousel from "../../../../shared/components/carousel/CarouselSingle";
import {Link} from "react-router-dom";
import { faStar,faStarHalf} from '@fortawesome/fontawesome-free-solid';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import CreateList from "../../../../shared/components/CreateList";


const ProductBrief = ({dispatch,productData,categories,theme,rtl}) => {

    const [images,setImages] = useState(false);
    const [mainCategoryData,setMainCategoryData] = useState(false);
    const [ratingHTML,setRatingHTML] = useState(false);
    const [viewAddToList,setViewAddToList]= useState(false);
    const [productIds,setProductIds]= useState([]);

    useEffect(()=>{

        if(productData.hasOwnProperty('p_images')){
            if(productData.p_images === null || productData.p_images.trim().length === 0)
                return;
            setImages(productData.p_images.split(","));
        }
        if(categories !== null)
            setMainCategoryData( categories[0]);

        setProductIds([productData.p_id]);

    },[productData,categories]);

    const toggleAddToList = ()=>{
        setViewAddToList(true);
    };

    const toggle=() => {
        setViewAddToList(prevState=>!prevState);
    };




    return (
        <Card style={{height:"auto"}}>
            <CardBody>
                <Row>
                    <Col xs={12}>
                        <Carousel>
                            {images !== false && images.map((image, index) => (
                                <div key={index}>
                                    <img src={process.env.REACT_APP_BASE_API_URL +"/assets/images/"+image.trim()} alt={`slide_${image.trim()}`} />
                                </div>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ul className="p-0" style={{listStyle:"none"}}>
                            <li><button className="btn btn-primary btn-sm" onClick={toggleAddToList}>Add <FontAwesomeIcon icon={faPlus} /></button></li>
                            <li className="pb-1 pt-2" style={{borderBottom: "1px solid #33333a"}}>
                                <h5>Rating</h5>
                                <h4>{productData.p_rating}(
                                    {[1,2,3,4,5].map((i) => {
                                        if(productData.p_rating>i){
                                            return <FontAwesomeIcon icon={faStar} key={i} style={{color:"orange"}}/>
                                        }else{
                                            if(productData.p_rating>=(i-.5))
                                                return <FontAwesomeIcon icon={faStarHalf} key={i} style={{color:"orange"}}/>
                                            else
                                                return <FontAwesomeIcon icon={emptyStar} key={i} style={{color:"orange"}}/>
                                        }

                                    })}
                                    )
                                </h4>
                            </li>
                            <li className="pb-1 pt-2" style={{borderBottom: "1px solid #33333a"}}>
                                <h5>Model</h5>
                                <h4>{productData.p_model}</h4>
                            </li>
                            <li className="pb-1 pt-2" style={{borderBottom: "1px solid #33333a"}}>
                                <h5>Manufacturer</h5>
                                <h4>{productData.m_name}</h4>
                            </li>
                            <li className="pb-1 pt-2" style={{borderBottom: "1px solid #33333a"}}>
                                <h5>Category</h5>
                                <h4><Link to={(productData!== false && productData.pc_permalink !==null) ? "/c/"+productData.pc_permalink : "/"}>{productData!== false && productData.pc_name}</Link></h4>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </CardBody>

            <AddProductToList productIds={productIds} toggle={toggle} isOpen={viewAddToList}/>

        </Card>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    productData:state.product.productData,
    categories:state.product.categories
}))(ProductBrief);