import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Row,
    Col,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    CardBody,
    Card
} from 'reactstrap';
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/fontawesome-free-solid";
import {faEdit, faShare, faShareAlt} from "@fortawesome/free-solid-svg-icons";

const ListItem = ({dispatch,rtl,theme,list,owner}) => {

    const history = useHistory();
    const [isOwner, setIsOwner] = useState(false);

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() => {
        if(owner ===true)
            setIsOwner(true);


    },[]);

    return (

        <Card className="col-12 col-sm-4">
            <CardBody className="text-center">
                <div className="listCardHeader row">
                    <div className="col-6 text-left">
                        <h4>{list.l_name}</h4>
                    </div>
                    <div className="col-6 text-right">
                        <h4>Products: {list.number_of_items}</h4>
                    </div>
                    <hr/>
                </div>

                {/*<Link to={"/c/"+c.pc_permalink}><h4>{c.pc_name}</h4></Link>*/}
                <Row>
                    <Col xs="12" className="text-left">
                        <p> {list.l_description}</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" className="text-left">
                        <ul className="list-group">
                            <li><p>Total Price: {list.total_price}</p></li>
                            <li><p>Price Trend: $$$$</p></li>

                        </ul>
                    </Col>
                </Row>
                <Row className="border-top mt-2 pt-2">
                    <Col xs="12" sm="6" className="text-left">
                        <h5>BOB THE builder</h5>
                    </Col>
                    <Col xs="12" sm="6" className="text-right">

                        <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}>
                            <FontAwesomeIcon icon={faEye} style={{margin:0}}/>
                        </a>
                        <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/l/'+list.l_permalink)}}>
                            <FontAwesomeIcon icon={faShareAlt} style={{margin:0}}/>
                        </a>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
}))(ListItem);