import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { sidebarReducer, themeReducer, rtlReducer,productReducer } from '../../redux/reducers/index';
import productsReducer from "../../redux/reducers/productsReducer";
import categoryReducer from "../../redux/reducers/categoryReducer";
import userReducer from "../../redux/reducers/userReducer";
import listsReducer from "../../redux/reducers/Lists/listsReducer";
import listReducer from "../../redux/reducers/Lists/listReducer";

const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form",
    theme: themeReducer,
    sidebar: sidebarReducer,
    rtl: rtlReducer,
    product:productReducer,
    products:productsReducer,
    category:categoryReducer,
    user: userReducer,
    lists:listsReducer,
    list:listReducer
});

const store = (process.env.NODE_ENV ==='development') ? createStore(reducer, applyMiddleware(thunk)
    +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
) :createStore(reducer, applyMiddleware(thunk));

export default store;