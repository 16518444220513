import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody , CardTitle,
} from 'reactstrap';
import ModelLists from '../../../../Models/Lists/Lists';
import {Link, useParams} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';
import ListItem from "../../../../shared/components/Lists/ListItem";

const FeaturedLists = ({ dispatch,featuredLists, loadedProducts,rtl,theme }) => {
    const { productId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const lsm = new ModelLists();


    // if(productData.vt_ids == null || productData.vt_ids.length === 0 || typeof productData.vt_ids === "undefined")
    //     return;

    const fetchData=()=>{
        return lsm.getFeaturedLists().then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }


            setIsLoaded(true);
            return true;
        });
    };

    useEffect(() => {
        // NOT NEEDED SINCE THE INITIAL LOAD IS HANDLED BY PARENT COMPONENT FOR ALL LISTS
        if(featuredLists===null)
            fetchData();
        else
            setIsLoaded(true)

    },[]);


    return (
        <Row>
            {!isLoaded &&
                <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
                <>
                    {typeof featuredLists !=="undefined" && featuredLists!== null && featuredLists.map((list,i)=>(
                        <ListItem key={i} list={list}/>
                    ))}
                </>
            }
        </Row>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    featuredLists: state.lists.featured
    // showVenueMenu:state.venue.showVenueMenu
}))(FeaturedLists);