import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import ModelProduct from '../../../../../../Models/Product';
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../../../shared/components/Notification";


const SpecsTables = ({ dispatch, loadedProducts ,theme,rtl}) => {

    const history = useHistory();
    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelProduct();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof loadedProducts !== 'undefined' || loadedProducts!==null){
            setLoaded(true);
        }
    },[loadedProducts]);

    useEffect(()=>{
        // fetchData().then(response => console.log("responded"));
    },[]);

    const removeMemberAccess = (member) => {
        // let formData = new FormData();
        // formData.append('userRoleId',member.ur_id);
        // formData.append('venueId',member.v_id);
        // formData.append('userId',member.u_id);
        //
        // vm.removeMemberAccess(formData).then(response => {
        //     if(response.data.status==='failed'){
        //         showNotification(theme,'danger',rtl,'Remove Access',response.data.msg);
        //         return;
        //     }
        //     showNotification(theme,'primary',rtl,'Remove Access',response.data.msg);
        //
        //     vm.getMembers(venueData.v_id).then(response=>{
        //         if (!response) {
        //             showNotification(theme,'danger',rtl,'Members',response.data.msg);
        //             return false;
        //         }
        //         return true;
        //     });
        //
        // });
    };

    const columns =[
        {
            width: 50,
            Header: "Options",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/product/'+cell.row.original.p_id)}}>
                            <FontAwesomeIcon icon={faEye} style={{margin:0}}/>
                        </a>
                    </>
                )
            }
        },
        {
            Header: 'Name',
            accessor: 'p_name',
            width: 300,
        },{
            Header: 'Model',
            accessor: 'p_model',
            width: 150
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (


            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
            </div>

    );
};

export default connect(state => ({
    loadedProducts:state.products.loadedProducts,
    rtl: state.rtl,
    theme: state.theme
}))(SpecsTables);
