import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelCategory from '../../Models/Category';
import ModelProducts from '../../Models/Products';
import ProductsTable from "../../shared/components/ProductsTable";
import Panel from "../../shared/components/Panel";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/fontawesome-free-solid";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useHistory } from "react-router-dom";



const Category = ({dispatch,category}) => {

    const { plink } = useParams();
    const history = useHistory();
    const mc = new ModelCategory();
    const mp = new ModelProducts();
    const [isLoaded,setLoaded] = useState(false);
    const [products,setProducts] = useState(false);

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(() => {
        mc.getCategoryPageData(plink).then(async response=>{
            // await setData(response.data.data);
            setLoaded(true);
        });
    },[]);

    useEffect(() => {
        setProducts(false);
        if(category!==null)
            mp.getAllProducts(category.pc_id,null,false).then(response=>{
                setProducts(response.data.data);
            });
    },[category]);

    const columns =[
        {
            width: 50,
            Header: "Options",
            Cell: ({ cell }) => {
                // debugger
                return (
                    <>
                        <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/product/'+cell.row.original.p_id)}}>
                            <FontAwesomeIcon icon={faEye} style={{margin:0}}/>
                        </a>
                    </>
                )
            }
        },
        {
            Header: 'Id',
            accessor: 'p_id',
            width: 50,
        },{
            Header: 'Name',
            accessor: 'p_name',
            width: 300,
        },{
            Header: 'Model',
            accessor: 'p_model',
            width: 150
        }
    ];

    return (
        isLoaded &&
            <Container className="dashboard">
                <Row>
                    {/*GIVE THIS IS A BACKGROUND IMAGE WITH ROUNDED CORNERS CENTER AND FILLED*/}
                    <Col xs={12} md={12} className={'pt-2 pb-2 text-center'}>
                        <Card>
                            <CardBody>
                                <h1>{category.pc_name}</h1>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} className={'pt-2 pb-2'}>
                        <Card>
                            <CardBody>
                                {products===false &&
                                    <div className="panel__refresh"><LoadingIcon/></div>
                                }
                                {products!== false &&
                                    <ProductsTable columnsStructure={columns}  productsData={products}/>
                                }
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    category:state.category.categoryData,
}))(Category);