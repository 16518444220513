import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import ModelProduct from '../../../../../Models/Product';
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../Notification";


const ProductsTable = ({ dispatch, products ,theme,rtl}) => {

    const history = useHistory();
    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelProduct();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof products !== 'undefined' || products!==null){
            setLoaded(true);
        }
    },[products]);

    useEffect(()=>{
        // fetchData().then(response => console.log("responded"));
    },[]);

    const columns =[
        {
            width: 50,
            Header: "Options",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <a className={ "btn btn-sm btn-dark m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); redirect('/product/'+cell.row.original.p_id)}}>
                            <FontAwesomeIcon icon={faEye} style={{margin:0}}/>
                        </a>
                    </>
                )
            }
        },
        {
            Header: 'Id',
            accessor: 'p_id',
            width: 50,
        },{
            Header: 'Name',
            accessor: 'p_name',
            width: 300,
        },{
            Header: 'Model',
            accessor: 'p_model',
            width: 150
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (


            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } products={products} />
                }
            </div>

    );
};

export default connect(state => ({
    loadedProducts:state.products.loadedProducts,
    rtl: state.rtl,
    theme: state.theme
}))(ProductsTable);
