import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody , CardTitle,
} from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import {Link, useParams} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';
import ListItem from "../../../../shared/components/Lists/ListItem";

const LatestLists = ({ dispatch,latestLists,rtl,theme }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const lsm = new ModelProducts();


    // if(productData.vt_ids == null || productData.vt_ids.length === 0 || typeof productData.vt_ids === "undefined")
    //     return;

    // const fetchData=()=>{
    //     return vm.getAllProducts().then(async response => {
    //
    //         if (response.data.status === 'failed') {
    //             alert('failed');
    //             return false;
    //         }
    //
    //
    //         setIsLoaded(true);
    //         return true;
    //     });
    // };

    useEffect(() => {
        // NOT NEEDED SINCE THE INITIAL LOAD IS HANDLED BY PARENT COMPONENT FOR ALL LISTS
        setIsLoaded(true)

    },[]);


    return (
        <Row>
            {!isLoaded &&
                <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
                <>
                    {typeof latestLists !=="undefined" && latestLists!== null && latestLists.map((list,i)=>(
                        <ListItem key={i} list={list}/>
                    ))}
                </>
            }
        </Row>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    latestLists: state.lists.latest
    // showVenueMenu:state.venue.showVenueMenu
}))(LatestLists);