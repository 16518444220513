import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody , CardTitle,
} from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import {Link, useParams} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import {showNotification} from "../../../../shared/components/Notification";
import LoadingIcon from 'mdi-react/LoadingIcon';
import ListItem from "../../../../shared/components/Lists/ListItem";

const MyLists = ({ dispatch,listsByUser, rtl,theme }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // NOT NEEDED SINCE THE INITIAL LOAD IS HANDLED BY PARENT COMPONENT FOR ALL LISTS
        setIsLoaded(true)

    },[]);


    return (
        <Row>
            {!isLoaded &&
                <div className="panel__refresh"><LoadingIcon/></div>
            }
            {isLoaded &&
                <>
                    {typeof listsByUser !=="undefined" && listsByUser!== null && listsByUser.map((list,i)=>(
                        <ListItem key={i} list={list} owner={true}/>
                    ))}
                    {listsByUser===null&&
                        <p>User has no lists</p>
                    }
                </>
            }
        </Row>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    listsByUser: state.lists.listsByUser
    // showVenueMenu:state.venue.showVenueMenu
}))(MyLists);