import React, { useEffect,useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ModalLogin from "../../../shared/components/Login/ModalLogin";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarUnauthenticated = ({dispatch,user}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [viewLogin,setViewLogin]= useState(false);


    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(()=>{

    },[user]);

    const toggleLogin = ()=>{
        setViewLogin(true);
    };
    const toggle=() => {
        setViewLogin(prevState=>!prevState);
    };

    return (
    <>
        <div className="topbar__profile">
                <button type="button" className="topbar__button topbar__button--desktop" onClick={toggleLogin}>
                    <p className="topbar__link-title">Login</p>
                </button>
        </div>
        <div className="topbar__profile">
            <Link className="" to="/register">
                <button type="button" className="topbar__button topbar__button--desktop">
                    <p className="topbar__link-title">Register</p>
                </button>
            </Link>

        </div>
        <ModalLogin toggle={toggle} isOpen={viewLogin}/>

    </>
    );
};

export default connect(state => ({
    user : state.user.main
}))(TopbarUnauthenticated);
