import React,{ useState,useEffect } from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelList from '../../Models/Lists/List';
import ProductsTable from "../../shared/components/ProductsTable";
import {connect} from "react-redux";
import ProductsWidget from "../../shared/components/ProductsWidget";
import Authentication from "../../Models/Authentication";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/fontawesome-free-solid";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import store from "../App/store";
import {setListData} from "../../redux/actions/Lists/listActions";
import {showNotification} from "../../shared/components/Notification";

const List = ({dispatch,user, listData,listProducts,theme,rtl}) => {

    const { permalink } = useParams();
    const lm = new ModelList();
    const [isLoaded,setLoaded] = useState(false);
    const [isListOwner,setIsListOwner] = useState(false);
    const [editMode,setEditMode] = useState(false);
    const am = new Authentication();


    useEffect(() => {


        // GET FEATURED List
        lm.getListByPermalink(permalink).then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            setLoaded(true);
        });

    },[]);

    useEffect(() => {
        if(am.isAuthenticated() && listData !==null){
            if(listData.l_owner_id !== user.u_id){
                setIsListOwner(false);
                return;
            }
            setIsListOwner(true);
        }
    },[listData,user]);

    useEffect(()=>{
        if(!editMode)
            return;

        // LOAD THE DATA INTO FORM
        Object.keys(listData).forEach(key => {
            document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
            document.querySelectorAll('select[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
            document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                el.value = listData[key];
            });
        });

    },[editMode])

    const editList=(e)=>{
        e.preventDefault();
        // USE THE LIST MODEL TO EDIT
        let formData = new FormData(e.currentTarget);
        lm.editList(formData).then(async response => {
            if (response.data.status === 'failed'){
                showNotification(theme,'danger',rtl,'Update List',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update List',response.data.msg);
            return;
        });
    }

    const uploadImages=()=>{

    }

    const toggleEditMode=()=>{
        if(editMode){
            setEditMode(false);
            return;
        }

        setEditMode(true);
    }

    return (
        <Container className="Home">
            {isLoaded &&
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                {editMode &&
                                <Row>
                                    <Col xs={12}>
                                        <form className="form--horizontal form" onSubmit={editList}>
                                            <div className="form__form-group row mt-1 mb-2">
                                                <label htmlFor="l_id" className="col-sm-2 form__form-group-label col-form-label">ID</label>
                                                <div className="col-sm-4">
                                                    <input type="text" className="form-control-sm" id="l_id" name="l_id"   readOnly/>
                                                </div>
                                                <label htmlFor="l_created" className="col-sm-2 form__form-group-label col-form-label">Created</label>
                                                <div className="col-sm-4">
                                                    <input type="text" className="form-control-sm" id="l_created" name="l_created"   readOnly/>
                                                </div>
                                            </div>
                                            <div className="form__form-group row mt-1 mb-2">
                                                <label htmlFor="l_name" className="col-sm-2 form__form-group-label col-form-label">Name</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control-sm" id="l_name" name="l_name" />
                                                </div>
                                            </div>
                                            <div className="form__form-group row mt-1 mb-2">
                                                <label htmlFor="l_description" className="col-sm-2 form__form-group-label col-form-label">Description</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control-sm" id="l_description" name="l_description" />
                                                </div>
                                            </div>
                                            <Row className={'form__form-group'}>
                                                <Col xs={'12'} sm={'12'} className={'text-right'}>
                                                    <hr/>
                                                    <button className="btn-danger btn btn-sm" onClick={toggleEditMode}>Close</button>
                                                    <button className="btn btn-primary btn-sm" type="submit">Update</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>

                                </Row>
                                }

                                {(isListOwner && !editMode) &&
                                    <>
                                        <Row>
                                            <Col xs={7} md={9}>
                                                <h3 className="page-title">{listData.l_name}</h3>
                                            </Col>
                                            <Col xs={5} md={3}>
                                                <button className="btn-secondary btn btn-sm" onClick={toggleEditMode}>Edit <FontAwesomeIcon icon={faEdit} /></button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <p>{listData.l_description}</p>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                {(!isListOwner&&!editMode) &&
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <h3 className="page-title">{listData.l_name}</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <p>{listData.l_description}</p>
                                            </Col>
                                        </Row>

                                    </>
                                }

                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <ProductsWidget products={listProducts} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }

        </Container>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    listData: state.list.listData,
    listProducts:state.list.listProducts,
    user : state.user.main
}))(List);